import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import posicionamiento1 from "../../../static/img/posicionamiento/posicionamiento1.jpg"
import posicionamiento2 from "../../../static/img/posicionamiento/posicionamiento2.jpg"
import posicionamiento3 from "../../../static/img/posicionamiento/posicionamiento3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Positioning"/>
            <Banner title="Positioning"/>
            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Client’s profile</h3>

                                <p>A publicly traded company and bond issuer engaged in
                                    the
                                    infrastructure development and operation sector, currently standing at a not very
                                    advantageous position
                                    on the market, with an analyst coverage of four institutions and placed in the
                                    medium
                                    liquidity category
                                    of the Mexican Stock Exchange’s ranking, decides to underpin its IR program,
                                    focusing on
                                    improving the
                                    company’s position in these fronts. This, with the dual objective of performing a
                                    comprehensive
                                    financial engineering program that includes the execution of multiple follow-ons, as
                                    well as the
                                    refinancing of its Senior Notes and issuance of a new Eurobond.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={posicionamiento1}
                                 alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={posicionamiento2}
                                 alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandate</h3>

                                <p>For its wide spectrum of innovative solutions,
                                    specifically
                                    tailored for publicly traded Mexican companies, IRStrat is engaged as strategic
                                    advisor,
                                    contracting the
                                    full-service package (IRStrat I + IRStrat II + IRStrat III), with the mandate of
                                    enhancing the delivery
                                    of message quality, optimizing the narrative and communication of the strategic
                                    message,
                                    as well as
                                    profiling and rapidly expand investor base and analyst coverage. The foregoing,
                                    aimed at
                                    strengthening
                                    the company’s positioning on the markets, to improve the trading of its shares and
                                    achieve a more
                                    attractive mark-to-market valuation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solution</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row mt-3 step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Strategy
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>IRStrat carries out an integral
                                            assessment of the factors
                                            that determine the issuer’s positioning, through an initial perception study
                                            covering the
                                            company’s investment thesis and its communication, its management team’s
                                            performance, its stock
                                            trading, and effectiveness of its IR program, among other aspects. With the
                                            collected information,
                                            IRStrat develops a strategic road map to lead our customer to the top levels
                                            of adoption of best
                                            practices and market awareness. Furthermore, IRStrat establishes the
                                            KPIs/targets and tracking
                                            mechanisms to ensure a successful execution.</p>
                                            <p>Our team, working with the senior management, begins to develop the
                                                company’s investment
                                                thesis, history and competitive advantage, as well as the communication
                                                style (qualitative and
                                                quantitative), and an optimal lobbying, prospecting and communication
                                                strategy.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Tactic
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>IRStrat performs a thorough training of our
                                            client’s executive team
                                            in financial communication. All IR materials are insightfully developed to
                                            ensure optimal quality
                                            (quarterly earnings release, annual report and regulatory filling, investor
                                            presentation, IR
                                            website, video production of senior executives’ messages, webcasts and
                                            investor fact sheet). In
                                            parallel, IRStrat implements a comprehensive intelligence system which
                                            rapidly gathers information
                                            on our client’s peers, industry and local, regional and international
                                            markets, with a go-to-market
                                            focus. Likewise, the investment thesis and short- and long-term guidance are
                                            developed and widely
                                            communicated.</p>
                                            <p>IRStrat develops an optimal prospection and communication strategy
                                                (including non-deal
                                                roadshows, site visits and investor days). Additionally, IRStrat draw up
                                                an active PR agenda,
                                                encompassing a deep participation in the most influential financial
                                                summits and forums,
                                                strategic lobbying with investment funds, retirement fund administrators
                                                and exchange-traded
                                                funds, as well as an exposure to the most influential financial media in
                                                Mexico and the United
                                                States. Likewise, IRStrat conducts presentations to the analysis and
                                                promotion departments of
                                                the different financial institutions and brokerages, focusing on
                                                expanding the awareness and
                                                interest of the company and its stock.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Results
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Our client climbs in the most prestigious IR
                                            rankings, becoming a
                                            renowned and publicly visible company in the markets. The client was able to
                                            successfully execute
                                            two follow-ones for US$850 million, refinanced his interest-bearing
                                            liabilities (reducing debt
                                            costs by 225 basis points and extending debt maturity profile to 7 years).
                                            Additionally, the
                                            company placed a new Eurobond under the same refinancing conditions for
                                            another US$300 million.
                                            Our client has an analyst coverage of 15 institutions and is placed in the
                                            high liquidity category
                                            of the Mexican Stock Exchange’s ranking, without disbursing a large amount
                                            of resources by
                                            maintaining its 2-person IR department and counting on IRStrat strategic
                                            support.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={posicionamiento3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
